import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/Projects.css';
import { projectData } from './Project';

export const Projects: React.FC = () => {
    const navigate = useNavigate();

    const handleProjectClick = (index: number) => {
        navigate(`/projects/${index}`);
    };

    return (
        <div className="projects-container">
            <div className="project-grid">
                {projectData.map((project, index) => (
                    <div key={index} className="project-item" onClick={() => handleProjectClick(index)}>
                        <div className="image-container">
                            {project.images?.[0] ? (
                                <img src={project.images[0]} alt={project.name} className="project-image" />
                            ) : (
                                <div className="placeholder-image">No Image</div>
                            )}
                            <div className="overlay">
                                <h2 className="project-name">{project.name}</h2>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
