import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Contact.css'; // Maak een aparte CSS-bestand voor aangepaste stijlen

export const Contact: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const templateParams = {
            from_name: name,
            from_email: email,
            message: message,
        };

        emailjs.send(
            'service_kylian_emailJS',  // Vervang dit met je eigen service ID
            'template_kg37532', // Vervang dit met je eigen template ID
            templateParams,
            'ByWUT0Gd12ilyS_B1'      // Vervang dit met je eigen user ID
        ).then((result) => {
            setSuccessMessage('Je bericht is succesvol verzonden!');
            setName('');
            setEmail('');
            setMessage('');
        }, (error) => {
            setSuccessMessage('Er is iets misgegaan. Probeer het opnieuw.');
        });
    };

    return (
        <div className="contact-page">
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="card-page card shadow-lg border-0">
                            <div className="card-header text-center py-4">
                                <h1>Contact</h1>
                            </div>
                            <div className="card-body p-4">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label className="bold" htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Message</label>
                                        <textarea
                                            className="form-control"
                                            id="message"
                                            rows={5}
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="button btn btn-primary btn-block">Send</button>
                                </form>
                                {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
