export interface Project {
    name: string;
    description: string;
    shortDescription?: string;
    url?: string;
    images?: string[];
    videos?: string[];
    textBlocks?: string[];
    iframeWidth?: string;
    iframeHeight?: string;
    imageWidth?: string;
    imageHeight?: string;
    videoWidth?: string;
    videoHeight?: string;
    skills?: string[]; // Voeg deze regel toe
}

export const projectData: Project[] = [
    {
        name: 'WASPGO',
        description: 'WaspGo provides an effective solution to prevent wasp infestations by capturing queen wasps before they can establish nests. ' +
            'The product includes two traps and two bottles of attractant liquid, specifically designed to lure the queens of the Common wasp and the German wasp. ' +
            'These traps should be set up outdoors and filled with a thin layer of the attractant in early spring. By capturing the queens early in the season, the formation of large nests, which can cause significant problems later in the summer, is prevented.\n' +
            'WaspGo\'s approach focuses on prevention rather than dealing with existing nests. By catching queens early, it prevents the establishment of nests with thousands of worker wasps, ' +
            'reducing the likelihood of painful stings and allergic reactions. Additionally, this method helps protect the bee population, as wasps kill bees. <a href="https://waspgo.nl" target="_blank">Bezoek WaspGo</a>' +
            '\n <i> (I helped develop the website and also manufacture and ship the traps and attractant liquid).</i>',
        shortDescription: 'Een webwinkel voor wespenvallen',
        url: 'https://waspgo.nl',
        images: ['/waspg.png', '/waspgo.png'],
        iframeWidth: '100%',
        iframeHeight: '600px',
        imageWidth: '80%',
        imageHeight: 'auto',
        skills: ['Laravel', 'PHP', 'JavaScript', 'SCSS', 'SQL'] // Voeg deze regel toe
    },
    {
        name: 'WATCHDAD',
        description: 'This project offers a solution by using sensor technology for continuous monitoring in the user\'s home environment. ' +
            'These sensors detect movements, allowing inactivity to be measured. For example, it can be set to expect movement between 6:00 AM and 9:00 AM. ' +
            'Unexpected inactivity can then be reported to a contact person, enabling timely intervention. <br /><a href="https://watchdad.irp.nl" target="_blank">Learn more about Watchdad</a>' +
            ' <i>(Ask me for login details to see the whole application)</i>',
        shortDescription: 'Oplossing voor continue monitoring',
        images: ['/watchd.png', '/watchdad.png'],
        imageWidth: '75%',
        imageHeight: '75%',
        skills: ['React', 'TypeScript', 'Java', 'Springboot', 'Node.js', 'CSS', 'Bootstrap', 'SQL', 'MariaDB', 'IoT'] // Voeg deze regel toe
    },
    {
        name: 'BIMKEEPER',
        description: 'Access to the demo can be obtained by requesting an account via the BIMkeeper website. The demo itself can be accessed on the BIMkeeper Sensors Demo page.' +
            'The demo showcases live sensor readings within a BIM model. Users start by selecting a complex, such as "Zekeringstraat," and then load the default 3D model. Sensor values are visually displayed in various colors depending on the readings.\n' +
            '\n' +
            'Different sensor types, including temperature, movement, light, humidity, and CO2, can be viewed. Detailed information, including graphs of sensor values over time, is available by selecting a space and opening the "Sensors" tab under "Details."\n' +
            '\n' +
            'Sensor values can also be displayed on the complex dashboard. BIMkeeper uses wireless LoRa sensors, which operate over long distances and have a long lifespan. Sensor settings can be adjusted via the "Sensor Settings" mobile app by Elsys. To register new sensors, users need to contact IRP for security purposes.' +
            '<br /><a href="https://demo.bimkeeper.com/demos" target="_blank">Learn more about BIMkeeper</a>',
        images: ['/bimkeepr.png'],
        videos: ['/BIMkeeperSensoren.mp4'],
        imageWidth: '70%',
        imageHeight: 'auto',
        videoWidth: '70%',
        videoHeight: 'auto',
        skills: ['React', 'TypeScript', 'JavaScript', 'HTML', 'CSS', 'LoRa', 'IoT'] // Voeg deze regel toe
    },
    {
        name: 'MOVIE REVIEWER',
        description: 'Een applicatie om films te zoeken en te beoordelen.',
        shortDescription: 'Zoek en beoordeel films',
        url: 'https://popcornmovieslist.netlify.app',
        images: ['/moviewatcher.png'],
        iframeWidth: '80%',
        iframeHeight: '600px',
        skills: ['React', 'TypeScript', 'CSS', 'HTML'] // Voeg deze regel toe
    },
    {
        name: 'ORDER PIZZA',
        description: 'Een applicatie om pizza te bestellen.',
        shortDescription: 'Zoek en beoordeel films',
        url: 'https://orderpizzaapp.netlify.app/',
        images: ['/orderpizza.png'],
        iframeWidth: '80%',
        iframeHeight: '600px',
        skills: ['React', 'TypeScript', 'CSS', 'HTML'] // Voeg deze regel toe
    },
    {
        name: 'SPLIT THE BILL',
        description: 'Een applicatie om rekeningen te splitsen en zien hoeveel je nog krijgt of moet betalen aan iemand.',
        shortDescription: 'Zoek en beoordeel films',
        url: 'https://splitthebillskyl.netlify.app/\n',
        images: ['/splitthebill.png'],
        iframeWidth: '80%',
        iframeHeight: '600px',
        skills: ['React', 'TypeScript', 'CSS', 'HTML'] // Voeg deze regel toe
    },
];
