import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {Home} from "./components/Home";
import {About} from "./components/About";
import {Projects} from "./components/Project/Projects";
import {Contact} from "./components/Contact";
import './css/App.css';
import {Navbar} from "./components/NavBar";
import * as React from "react";
import {ProjectDetails} from "./components/Project/ProjectDetails";

const App: React.FC = () => {

    return (
        <Router>
            <div>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/about" element={<About/>}/>
                    <Route path="/projects" element={<Projects/>} />
                    <Route path="/projects/:projectId" element={<ProjectDetails />} />
                    <Route path="/contact" element={<Contact/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
