import * as React from 'react';
import '../css/ToggleButton.css';

interface ToggleButtonProps {
    isDarkMode: boolean;
    toggleDarkMode: () => void;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({ isDarkMode, toggleDarkMode }) => {
    return (
        <span className={` ${isDarkMode ? 'dark' : 'light'}`} onClick={toggleDarkMode}>
            {isDarkMode ?  '☀️': '🌙'}
        </span>
    );
};
