import React from 'react';
import { useParams } from 'react-router-dom';
import '../../css/ProjectDetails.css';
import { projectData } from './Project';
import {SkillsList} from './SkillsList';

export const ProjectDetails: React.FC = () => {
    const { projectId } = useParams<Record<string, string | undefined>>();
    const projectIndex = parseInt(projectId || '', 10);
    const selectedProject = projectData[projectIndex];

    if (!selectedProject) {
        return <div className="not-found">Project not found</div>;
    }

    const combinedContent: { type: string, content: string }[] = [];
    const images = selectedProject.images?.slice(1) || [];
    const videos = selectedProject.videos || [];
    const textBlocks = selectedProject.textBlocks || [];

    const maxLength = Math.max(images.length, videos.length, textBlocks.length);

    for (let i = 0; i < maxLength; i++) {
        if (images[i]) combinedContent.push({ type: 'image', content: images[i] });
        if (textBlocks[i]) combinedContent.push({ type: 'text', content: textBlocks[i] });
        if (videos[i]) combinedContent.push({ type: 'video', content: videos[i] });
        if (textBlocks[i + images.length]) combinedContent.push({ type: 'text', content: textBlocks[i + images.length] });
    }

    return (
        <div className="project-details">
            <h1 className="project-name">{selectedProject.name}</h1>
            <div className="project-content">
                <div className="main-content">
                    {selectedProject.url ? (
                        <div className="iframe-container">
                            <iframe
                                src={selectedProject.url}
                                width={selectedProject.iframeWidth || '100%'}
                                height={selectedProject.iframeHeight || '600px'}
                                className="project-iframe"
                                title={selectedProject.name}
                            ></iframe>
                            <p
                                className="project-description"
                                dangerouslySetInnerHTML={{ __html: selectedProject.description }}
                            ></p>
                        </div>
                    ) : (
                        <div className="content-container">
                            {combinedContent.map((item, index) => (
                                <div key={index} className="content-item">
                                    {item.type === 'image' && (
                                        <img
                                            src={item.content}
                                            alt={`${selectedProject.name} screenshot ${index + 1}`}
                                            className="project-image"
                                            style={{
                                                width: selectedProject.imageWidth || '100%',
                                                height: selectedProject.imageHeight || 'auto'
                                            }}
                                        />
                                    )}
                                    {item.type === 'video' && (
                                        <video
                                            src={item.content}
                                            controls
                                            className="project-video"
                                            style={{
                                                width: selectedProject.videoWidth || '100%',
                                                height: selectedProject.videoHeight || 'auto'
                                            }}
                                        ></video>
                                    )}
                                    {item.type === 'text' && (
                                        <p className="project-description">
                                            {item.content}
                                        </p>
                                    )}
                                </div>
                            ))}
                            <p
                                className="project-description"
                                dangerouslySetInnerHTML={{ __html: selectedProject.description }}
                            ></p>
                        </div>
                    )}
                </div>
                {selectedProject.skills && (
                    <div className="sidebar">
                        <SkillsList skills={selectedProject.skills} />
                    </div>
                )}
            </div>
        </div>
    );
};
