import '../css/About.css';
import { useState } from "react";

export const About: React.FC = () => {
    const [activeSection, setActiveSection] = useState('overMij');

    const renderContent = () => {
        switch (activeSection) {
            case 'overMij':
                return (
                    <div className="content-section">
                        <h2>About Me</h2>
                        <p>I'm Kylian Lahaye, a passionate full-stack developer who loves creating
                            websites and applications. Here you will find more information about my skills, education and experiences.</p>
                        <div className="personal-details">
                            <ul>
                                <li><strong>Name:</strong> <span>Kylian Lahaye</span></li>
                                <li><strong>Phone:</strong> <span>(+31 631644290)</span></li>
                                <li><strong>Experience:</strong> <span>2+ Years</span></li>
                                <li><strong>Nationality:</strong> <span>Dutch</span></li>
                                <li><strong>Status:</strong> <span>Full Stack Developer</span></li>
                                <li><strong>Email:</strong> <span><a href="mailto:kkylian@live.nl">kkylian@live.nl</a></span></li>
                                <li><strong>Languages:</strong> <span>English (fluent), Dutch (Native), Spanish (Basic)</span></li>
                            </ul>
                        </div>
                    </div>
                );
            case 'ervaring':
                return (
                    <div className="content-section">
                        <h2>Experience</h2>
                        <p>I have 2 years of experience in the field and 5 years of academic study in the field
                            software development. During my career I have worked on various projects,
                            ranging from small websites to large web applications</p>
                    </div>
                );
            case 'onderwijs':
                return (
                    <div className="content-section">
                        <h2>Education</h2>
                        <ul>
                            <li>Bachelor in Computer Science - Hogeschool van Amsterdam | 2018 - 2024</li>
                            <li>Hotel- en Hospitality management MBO+ - ROC van Amsterdam | 2015-2017</li>
                            <li>HAVO Economie & Maatschappij - A. Roland Holst College | 2009-2015</li>
                        </ul>
                    </div>
                );
            case 'skills':
                return (
                    <div className="content-section">
                        <h2>Skills</h2>
                        <ul className="skills-list">
                            <li>React <span className="stars">★★★★☆</span></li>
                            <li>JavaScript <span className="stars">★★★★☆</span></li>
                            <li>TypeScript <span className="stars">★★★★☆</span></li>
                            <li>HTML & CSS <span className="stars">★★★★☆</span></li>
                            <li>Laravel <span className="stars">★★★☆☆</span></li>
                            <li>PHP <span className="stars">★★★☆☆</span></li>
                            <li>Node.js <span className="stars">★★★☆☆</span></li>
                            <li>Java <span className="stars">★★☆☆☆</span></li>
                            <li>SQL & NoSQL databases <span className="stars">★★★☆☆</span></li>
                            <li>Git <span className="stars">★★★★☆</span></li>
                            <li>Laravel <span className="stars">★★★☆☆</span></li>
                        </ul>
                    </div>
                );
            case 'cursussen':
                return (
                    <div className="content-section">
                        <h2>Courses</h2>
                        <ul>
                            <li>The Ultimate React Course 2024: React, Next.js, Redux & More - Udemy</li>
                            <li>React JS Web Development - The Essentials Bootcamp - Udemy</li>
                            <li>Java Multithreading - Udemy</li>
                            <li>React and Redux Masterclass - Udemy</li>
                        </ul>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="about-page container">
            <div className="sidebar">
                <button onClick={() => setActiveSection('overMij')}
                        className={`btn ${activeSection === 'overMij' ? 'active' : ''}`}>About Me
                </button>
                <button onClick={() => setActiveSection('ervaring')}
                        className={`btn ${activeSection === 'ervaring' ? 'active' : ''}`}>Experience
                </button>
                <button onClick={() => setActiveSection('onderwijs')}
                        className={`btn ${activeSection === 'onderwijs' ? 'active' : ''}`}>Education
                </button>
                <button onClick={() => setActiveSection('skills')}
                        className={`btn ${activeSection === 'skills' ? 'active' : ''}`}>Skills
                </button>
                <button onClick={() => setActiveSection('cursussen')}
                        className={`btn ${activeSection === 'cursussen' ? 'active' : ''}`}>Courses
                </button>
            </div>
            <div className="content">
                {renderContent()}
            </div>
        </div>
    );
}
