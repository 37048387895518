import React from 'react';
import '../../css/SkillsList.css';

interface SkillsListProps {
    skills: string[];
}

export const SkillsList: React.FC<SkillsListProps> = ({ skills }) => {
    return (
        <div className="skills-list">
            <h3>Skills Used:</h3>
            <ul>
                {skills.map((skill, index) => (
                    <li key={index} className="skill-item">{skill}</li>
                ))}
            </ul>
        </div>
    );
};
