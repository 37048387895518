import * as React from 'react';
import { useState } from 'react';
import '../css/Home.css';
import { Link } from "react-router-dom";
import { projectData } from "./Project/Project";

export const Home: React.FC = () => {
    const [isFlipped, setIsFlipped] = useState(false);

    const projectnr = projectData.length;

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <>
            <div className="home-container">
                <section className="hero-section">
                    <div className="hero-content">
                        <div className="hero-text">
                            <h5 className="hero-title">Full-stack Developer</h5>
                            <h2 className="hero-subtitle">Hello, I'm Kylian Lahaye</h2>
                            <p className="hero-description">Welcome to my portfolio page! Here you'll find information about me and my projects. If you have any questions or would like to get in touch, please visit the contact page.</p>
                            <div className="button-container">
                                <Link to="/projects" className="view-work-btn">View My Work</Link>
                                <a href="/CVKylian1.0.pdf" className="download-cv-btn" download>Download My CV</a>
                            </div>
                        </div>
                        <div className={`hero-image-container ${isFlipped ? 'flipped' : ''}`} onClick={handleFlip}>
                            <div className="hero-image">
                                <div className="hero-image-front">
                                    <img src="/iklinkedin.jpeg" alt="Kylian Lahaye" />
                                </div>
                                <div className="hero-image-back">
                                    <p>Hello!🙋‍</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br />
                <br />

                {/* Footer section */}
                <footer className="footer-section">
                    <div className="footer-content">
                        <div className="footer-item">
                            <h3>Projects completed</h3>
                            <p>{projectnr}</p>
                        </div>
                        <div className="footer-item">
                            <h3>Years of Experience</h3>
                            <p>2 years in the field + 5 years of academic study</p>
                        </div>
                        <div className="footer-item">
                            <h3>Skills</h3>
                            <p>5</p>
                        </div>
                        <div className="footer-item">
                            <p>All rights reserved | Kylian Lahaye</p>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
};
