import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../css/NavBar.css';
import { ToggleButton } from "./ToggleButton";

export const Navbar: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
        document.body.classList.toggle('dark-mode', !isDarkMode);
    };

    return (
        <>
            <div className={`navbar-container ${isDarkMode ? 'dark-mode' : ''}`}>
                <div className="navbar-brand-container">
                    <Link to="/" className="navbar-brand">
                        KYLIAN
                    </Link>
                </div>
                <nav className="navbar navbar-expand-lg">
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={toggleMenu}
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded={isMenuOpen}
                        aria-label="Toggle navigation"
                    >
                        <span className={`navbar-toggler-icon ${isMenuOpen ? 'open' : ''}`}></span>
                    </button>
                    <div className={`collapse navbar-collapse justify-content-center ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/projects" className="nav-link" onClick={closeMenu}>WORK</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" className="nav-link" onClick={closeMenu}>ABOUT</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact" className="nav-link" onClick={closeMenu}>CONTACT</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="linkedin-container">
                    <a
                        href="https://www.linkedin.com/in/kylian-lahaye-236594149/"
                        className="btn-linkedin"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-linkedin"></i>
                    </a>
                </div>
                <div className='btn align-self-center ml-auto'>
                    <ToggleButton isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
                </div>
            </div>
            <div className={`content-container ${isMenuOpen ? 'menu-open' : ''}`}>
                {/* De rest van je pagina-inhoud hier */}
            </div>
        </>
    );
};
