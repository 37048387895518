import * as React from 'react';
import { createRoot } from 'react-dom/client';
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Zorg ervoor dat 'root' nooit null is
const rootElement = document.getElementById('root');
if (rootElement) {
    const root = createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
} else {
    console.error('Root element not found');
}

// Als je de prestaties in je app wilt meten, geef dan een functie door
// om resultaten te loggen (bijvoorbeeld: reportWebVitals(console.log))
// of stuur naar een analytics endpoint. Meer informatie: https://bit.ly/CRA-vitals
reportWebVitals();
